import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In an ideal world, a chatbot is self-contained and `}<a parentName="p" {...{
        "href": "https://yourbusiness.azcentral.com/customer-escalation-mean-12822.html"
      }}>{`escalation`}</a>{` is an obsolete concept. After all, if the chatbot is doing what it’s supposed to, shouldn’t it be able to handle any and all support questions? According to Paul Lasserre, former VP of Product Management AI for Genesys, companies need to `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/09/26/exploring-customer-service-chatbots-with-the-experts/"
      }}>{`embrace escalation`}</a>{` in order to provide a good experience.`}</p>
    <p>{`While machine learning and natural language processing has come a long way, there are some issues and situations that still benefit from a human touch. Escalation should be a part of the support solution and not something to be shunned. In a recent survey, the ability to escalate was the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/04/24/customer-service-chatbots-providing-an-effective-solution/"
      }}>{`number one area consumers would like to see improvement in`}</a>{`. Knowing when to escalate and successfully executing that handoff can be the difference between a satisfied customer and one lost to churn.`}</p>
    <h3><strong parentName="h3">{`Outside the scope of knowledge`}</strong></h3>
    <p>{`Not all support issues have a clear path to resolution. Customers will ask complicated questions with no simple answer in sight. Having a chatbot handle these support issues will most likely lead to an unsatisfied customer.`}</p>
    <p>{`Chatbots are difficult to get right, and even automating simple level one support issues take a lot of resources. It would take a highly advanced chatbot to be able to resolve the level 2 or 3 issues. Complicated issues with multiple components would currently be too much for the standard customer service chatbot to handle, leaving the customer without an answer and stuck trying to navigate to a solution. If someone were stuck talking to a chatbot that couldn’t help them, they would be frustrated and displeased with their service.`}</p>
    <p>{`Instead of trapping customers in an experience that can’t provide them with answers, show them a way out. Developing an escalation process to route customers to the agent most suited to help them will cut down on the time their support issue goes unresolved. They’ll get a quick resolution and agents will be able to help more customers. One of our customers even uses chatbot interactions in order to correctly route each user to the best agent, saving even more time.`}</p>
    <h3><strong parentName="h3">{`Adding a human touch`}</strong></h3>
    <p>{`Chatbots are getting better at performing empathy. The cold and clinical responses of yesterday have been replaced with more human emotional messages. IBM, among others, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/07/30/brand-strategies-for-conversational-interfaces/"
      }}>{`has developed an “empathy suite”`}</a>{` of products to emulate that care a real agent provides when a customer is in distress. That being said, empathy is still a challenge to get right.`}</p>
    <p>{`There are still instances where it’s safer to escalate to a human agent. Perhaps a customer faced catastrophic failure or their personal information was compromised; it wouldn’t be unusual if they were in a state of panic. When a customer is looking for support in that heightened emotional state, a chatbot can feel uncaring. Training a natural language model to exhibit empathy is really challenging, and it’s better to hand off this support ticket to an agent than have the chatbot do empathy poorly. A human agent will be able to better provide comfort outside out a script or conversation flow.`}</p>
    <p>{`It’s important to build a chatbot to identify these situations so it can proactively send each customer to the agent most suited to the issue at hand. Customers will feel more seen and taken care of by talking to another person who can show understanding to their situation.`}</p>
    <h3><strong parentName="h3">{`Trust the customer`}</strong></h3>
    <p>{`The chatbot won’t be for everyone. When faced with one, some customers would still prefer to talk to a human agent. If someone asks to be transferred to a human, it’s better to escalate them immediately instead of keeping them in an experience that brings them no joy.`}</p>
    <p>{`Think of it from the customer’s point of view. They’re seeking support, which people don’t normally do unless there’s something wrong. This customer most likely is not going to be in the best mood. Instead of believing you know better than the customer, trust them to find their own happy path.`}</p>
    <h3><strong parentName="h3">{`Put the customer first`}</strong></h3>
    <p>{`Escalation doesn’t mean the chatbot is a failure.The point of a chatbot is to provide higher quality care and increase customer satisfaction. If it means escalating people out of the experience at a higher volume, so be it. Having a low escalation rate is nice, but it means nothing if it comes at the cost of the company’s reputation for good service. Put the customer first and they’ll keep coming back.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      